import { Injectable } from '@angular/core';
import { map, Observable, skipWhile } from 'rxjs';
import { AccessRole, AccessRoleProperties } from '../user/permissions';
import { AuthService } from './auth.service';
import { PortalService } from './portal.service';
import { convertTimestampsPipe } from '../pipes/convert-firestore-timestamp.pipe';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private ps: PortalService, private auth: AuthService) { }

  hasRole(role: AccessRole) {
    return this.auth.user$.pipe(
      map(user => {
        const accessRole = { ...AccessRoleProperties.find(accessRole => accessRole.role === role) };

        if (!accessRole || accessRole?.properties?.length === 0 || !user) {
          return false;
        }

        if (accessRole.operator === 'OR') {
          return accessRole.properties?.some(property => user && user[property]);
        }

        return accessRole.properties?.every(property => user && user[property]);
      })
    );
  }

  portalExpired(): Observable<boolean> {
    return this.ps.portal.pipe(
      skipWhile(portal => portal === null),
      map(portal => {
        return (portal?.subscriptionEnd.getTime() || 0) < Date.now();
      })
    );
  }

  reporting() {
    const allowedPortals = ['Z12345_CS Demo', 'R04815_Demo - CommCorp', 'lvQ9dq1NWCJHCa0bpIdF', 'uFn5IW4XnPM9aLw8hvvf', 'oktsv6b5j7rUmKG2brtx'];

    return this.auth.user$.pipe(
      map(user => {
        return user?.hqAccess || user?.portals?.some(portal => allowedPortals.includes(portal)) || false;
      })
    );
  }

  showNpsSurvey() {
    return this.auth.user$.pipe(
      convertTimestampsPipe(),
      map(user => {
        // Check if survey has already been completed on the user
        if (user?.lastNpsSurveyCompletedDate || user?.lastNpsSurveyCompletedId) {
          return false;
        }

        const now = new Date(Date.now());
        const createdBeforeDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());

        // Check if user has been created in the past month
        if (user?.createdDate && user.createdDate > createdBeforeDate) {
          return false;
        }

        return true;

      })
    );
  }

  editCustomSelfRegistrationLink() {
    return this.auth.user$.pipe(
      map(user => user?.hqAccess || !environment.vx || false)
    );
  }
}
