import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { map, distinctUntilChanged, switchMap, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PortalService } from '../services/portal.service';
import { EvalueeListService } from '../services/evaluee-list.service';
import { Manager, Evaluee } from '@career-scope/models';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatOptgroup, MatOption } from '@angular/material/core';




interface SearchGroup {
  searchItems: SearchItem[],
  type: 'Evaluee' | 'In Progress' | 'Register' | 'Archived' | 'Manager';
}

type SearchGroupType = SearchGroup['type'];

interface SearchItem {
  uid: string;
  portalId: string;
  fullName: string;
  type: SearchGroupType;
}

@Component({
    selector: 'app-evaluee-search',
    templateUrl: './evaluee-search.component.html',
    styleUrls: ['./evaluee-search.component.scss'],
    imports: [MatFormField, MatInput, FormsModule, MatAutocompleteTrigger, ReactiveFormsModule, MatIcon, MatPrefix, NgIf, MatIconButton, MatSuffix, MatAutocomplete, NgFor, MatOptgroup, MatOption, AsyncPipe]
})
export class EvalueeSearchComponent {
  @Input() portalId = '';
  searchControl = new FormControl<string>('', { nonNullable: true });

  lists$: Observable<SearchGroup[]> = this.searchControl.valueChanges.pipe(
    debounceTime(1000), // Apply debounce to all value changes - lower to have quicker results
    distinctUntilChanged(), // Ensure distinct values only
    switchMap(search => {
      if (!search || search.length <= 2) {
        return of([]); // Return an empty array observable if search is empty or less than or equal to 2 characters
      }
      const evaluees$ = this.els.searchEvaluees(search, this.ps.currentUserManager.value?.restrictViewEvaluees ? this.ps.currentUserManager.value?.fullName : undefined);
      const managers$ = this.ps.managers.pipe(
        map(managers => managers?.filter(manager => manager.fullName.toLowerCase().startsWith(search.toLowerCase())) || [])
      );
      return combineLatest([evaluees$, managers$]).pipe(
        map(([evaluees, managers]) => this.refreshEvalueesManagers(evaluees, managers))
      );
    }),
  );

  constructor(
    private ps: PortalService,
    private els: EvalueeListService,
    private router: Router,
  ) { }

  private refreshEvalueesManagers(evaluees: Evaluee[], managers: Manager[]): SearchGroup[] {
    const searchGroupMap: Map<SearchGroupType, SearchGroup> = new Map();

    // Function to get or create a search group
    const getOrCreateGroup = (type: SearchGroupType): SearchGroup => {
      if (!searchGroupMap.has(type)) {
        searchGroupMap.set(type, { searchItems: [], type });
      }
      return searchGroupMap.get(type)!;
    };

    evaluees.forEach(evaluee => {
      let groupType: SearchGroupType = 'Evaluee';

      switch (evaluee.status) {
        case 'completed':
          groupType = 'Evaluee';
          break;
        case 'in progress':
          groupType = 'In Progress';
          break;
        case 'archived':
          groupType = 'Archived';
          break;
        case 'new':
          groupType = 'Register';
          break;
      }

      const temp: SearchItem = {
        uid: evaluee.uid || '',
        portalId: evaluee.portalId,
        fullName: evaluee.fullName,
        type: groupType
      };

      getOrCreateGroup(groupType).searchItems.push(temp);
    });

    if (managers.length > 0) {
      const managersGroup = getOrCreateGroup('Manager');
      managers.forEach(manager => {
        const temp: SearchItem = {
          uid: manager.id || '',
          portalId: manager.portalId,
          fullName: manager.fullName,
          type: 'Manager'
        };
        managersGroup.searchItems.push(temp);
      });
    }

    // Add all populated groups to the searchGroups array
    const searchGroups: SearchGroup[] = [];
    const predefinedOrder: SearchGroupType[] = ['Evaluee', 'In Progress', 'Archived', 'Register', 'Manager'];
    predefinedOrder.forEach(type => {
      if (searchGroupMap.has(type)) {
        searchGroups.push(searchGroupMap.get(type)!);
      }
    });

    return searchGroups;
  }

  searchItemSelected(searchItem: SearchItem) {
    if (searchItem.type === 'Evaluee' || searchItem.type === 'Archived') {
      this.searchControl.setValue('');
      this.router.navigate(['/' + searchItem.portalId + '/reports/evaluee/' + searchItem.uid]);
    } else if (searchItem.type === 'Register' || searchItem.type === 'In Progress') {
      this.searchControl.setValue('');
      this.router.navigate(['/' + searchItem.portalId + '/invitations'], { queryParams: { name: searchItem.fullName, id: searchItem.uid } });
    } else if (searchItem.type === 'Manager') {
      this.searchControl.setValue('');
      this.router.navigate(['/' + this.portalId + '/managers/' + searchItem.uid]);
    }
  }

  clearSelected() {
    this.searchControl.setValue('');
  }

  getName(searchItem: SearchItem) {
    return searchItem ? searchItem.fullName : '';
  }
}
