import { Component, inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { PortalService } from '../../../services/portal.service';
import { PermissionsService } from '../../../services/permissions.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PredefinedMessageTypes } from '../../../models/supportMessage.model';
import { SupportMessageDialogComponent } from '../../../support-message-dialog/support-message-dialog.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-top-alert-bar',
    templateUrl: './top-alert-bar.component.html',
    styleUrls: ['./top-alert-bar.component.scss'],
    animations: [
        trigger('fold', [
            transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate('.3s ease', style({ height: '*' }))]),
            transition(':leave', [style({ height: '*', overflow: 'hidden' }), animate('.3s ease', style({ height: 0 }))])
        ])
    ],
    imports: [NgIf, MatButton, MatIcon, AsyncPipe]
})
export class TopAlertBarComponent {
  private permissions = inject(PermissionsService);
  portalExpired$ = this.permissions.portalExpired();

  constructor(
    public as: AuthService,
    public router: Router,
    public ps: PortalService,
    public dialog: MatDialog,
  ) {}

  reactivatePortal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = PredefinedMessageTypes.RENEWAL;
    this.dialog.open(SupportMessageDialogComponent, dialogConfig);
  }
}
