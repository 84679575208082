import { Component, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManagerProfileDialogComponent } from './manager-profile-dialog/manager-profile-dialog.component';
import { ChangeSitesDialogComponent } from './change-sites-dialog/change-sites-dialog.component';
import { EvalueeListService } from '../../services/evaluee-list.service';
import { PortalService } from '../../services/portal.service';
import { environment } from '../../../environments/environment';
import { PermissionsService } from '../../services/permissions.service';
import { SupportMessageDialogComponent } from '../../support-message-dialog/support-message-dialog.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatNavList, MatListItem } from '@angular/material/list';
import { TopAlertBarComponent } from './top-alert-bar/top-alert-bar.component';
import { MatIconButton, MatAnchor, MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { EvalueeSearchComponent } from '../../evaluee-search/evaluee-search.component';
import { HqPortalSearchComponent } from './hq-portal-search/hq-portal-search.component';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    imports: [
        NgIf,
        MatSidenavContainer,
        MatSidenav,
        MatToolbar,
        MatNavList,
        MatListItem,
        RouterLink,
        NgClass,
        MatSidenavContent,
        TopAlertBarComponent,
        MatToolbarRow,
        MatIconButton,
        MatIcon,
        MatAnchor,
        RouterLinkActive,
        MatTooltip,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        EvalueeSearchComponent,
        HqPortalSearchComponent,
        AsyncPipe,
        MatButtonModule
    ]
})
export class ShellComponent {
  permissions = inject(PermissionsService);
  breakpointObserver = inject(BreakpointObserver);
  portalId = '';
  dialogOpen = false;
  currentYear = new Date().getFullYear();
  environment = environment;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  reportingAccess$: Observable<boolean> = this.permissions.reporting();

  constructor(
    public as: AuthService,
    public router: Router,
    public ps: PortalService,
    public dialog: MatDialog,
  ) {
    this.ps.shellPortalId.subscribe(id => {
      this.portalId = id || '';
    });
  }

  async openProfile() {
    const user = await this.as.currentUser();
    if (user) {
      this.dialogOpen = true;

      const dialogRef = this.dialog.open(ManagerProfileDialogComponent);
      dialogRef.afterClosed().subscribe(name => {
        if (name) {
          this.as.updateAdminUser({ name });
          if (!user.hqAccess) {
            this.ps.updateManagerName(name);
          }
          // TODO: If the user is not HQ, the name needs to change in the manager document associated with the portal
          // TODO: Check for other portals
          // TODO: If the user is a Counselor to update evaluee records.
        }
        this.dialogOpen = false;
      });
    }
  }

  async openChangeSites() {
    const user = await this.as.currentUser();
    if (user) {
      const dialogConfig = new MatDialogConfig();
      if (user.portals) {
        dialogConfig.data = {
          portals: user.portals.filter(port => port !== user.lastPortalId)
        };

        this.dialog.open(ChangeSitesDialogComponent, dialogConfig);
      }

    }
  }

  exportEvalueeDetails() {
     alert('This feature is not available at this time.')
    // this.els.getLoadedCurrentEvaluees().pipe(take(1)).subscribe(evaluees => {
    //   console.log(evaluees);
    //   const csvContent = [
    //     [
    //       'Name',
    //       'Logon Name',
    //       'ID',
    //       'Status',
    //       'Last Activity',
    //       'Last Activity Date',
    //       'Interest Complete',
    //       'Exercise Complete',
    //     ],
    //     ...evaluees.map(evaluee => [
    //       evaluee.fullName,
    //       evaluee.logonName,
    //       evaluee.uid,
    //       evaluee.status,
    //       evaluee.lastActivity,
    //       evaluee.lastActivityDate,
    //       evaluee.interestStatus,
    //       evaluee.exerciseStatus
    //     ])
    //   ]
    //     .map(e => e.join(','))
    //     .join('\n');

    //   //console.log(csvContent);
    //   const link = document.createElement('a');
    //   link.id = 'download-csv';
    //   link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent));
    //   link.setAttribute('download', this.portalId + ' Evaluee Details.csv');
    //   document.body.appendChild(link);
    //   const element: HTMLElement = document.querySelector('#download-csv') as HTMLElement;
    //   element.click();

    // });
  }

  sendMessage() {
    this.dialog.open(SupportMessageDialogComponent);
  }

}
